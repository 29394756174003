<template>
<v-container fluid class="">

<v-row class="m-0 p-0"  id="testimonials"  >
    <v-col class="my-0 py-0">
 <h6 class="text-uppercase font-weight-bold ml-2">Testimonials</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto pl-3" style="width: 60px;">
            </v-col>
</v-row>
 <v-row class="p-3">
    <v-col
      v-for="n in 5"
      :key="n"
      class="d-flex "
      cols="12" sm="4"
    >
      <v-img
        :src="require(`../assets/images/testimonial${n}.jpeg`)"
          contain max-height="500px"
          height= "300px"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
     <v-col class="d-flex" cols ="12"  sm="4">
 <video         :src="require(`../assets/images/video1.mp4`)"  style="width: 100%;height: auto;max-height: 100%;"  class="mx-0" controls>
      </video>
     
    </v-col>
     <v-col class="d-flex" cols ="12"  sm="4">
      <video         :src="require(`../assets/images/video2.mp4`)"  style="width: 100%;height: auto;max-height: 100%;"  class="mx-0" controls>
      </video>
    </v-col>
  </v-row>
  </v-container>

</template>

<style scoped>
.blackbar
{
    border: 1px solid black;
    margin: 0 auto 16px;
    width: 50px;
}
p{
font-family: 'Architects Daughter';
    color: black;
    font-size: 24px;
    }
</style>
<script>


export default {
  
}
</script>