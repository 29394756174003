<template>
<v-container fluid class="p-0 m-0 mywidth">
    <v-container fluid class="p-0 m-0 d-none d-sm-block">
        <div class="mydiv">

        <v-img :src="require(`../assets/images/3.jpeg`)"  class="p-0 m-0" contain    ></v-img>
        <div class="myrelative">
  <h3 class="za">Dr. Nikhil Patil <br/>
     Advanced Heart Care Clinic</h3>

        <button class="x mt-5" @click="openWhatsapp">Schedule Appointment Now</button>
        </div>
      
        </div>
            </v-container>
        
            <v-container fluid class="p-0 m-0 d-block d-sm-none">
                <div class="mydiv">   </div>
<v-img :src="require(`../assets/images/3.jpeg`)" class="p-0 m-0"  width="100%" ></v-img>
<h4 class="z">Dr. Nikhil Patil <br/>
     Advanced Heart Care Clinic</h4>
                    <button class="y" @click="openWhatsapp">Schedule Appointment Now</button>

            </v-container>
            </v-container>
</template>
<style  scoped>
.myrelative
{
    position: absolute;
    top: 15%;
    left: 10%;
}
.za
{

    color: rgb(12, 38, 59);
    font-size: 40px;
    font-family: 'Muli';
    font-weight: bold;
    left: 5%;
}
.z
{
     position: absolute;
    top: 10vh;
    font-size: 18px;
    color: white;
    font-family: 'Muli';
    font-size: 2;
    left: 5%;
}
.y
{
    position: absolute;
    top: 20vh;
    left: 5%;
  background-color: rgb(238,52,78);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 18px 15px;
    border-radius: 6px;
    transition: all 0.25s ease-in-out 0s;
}
.x
{
  background-color: rgb(238,52,78);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 18px 60px;
    border-radius: 6px;
    transition: all 0.25s ease-in-out 0s;
}
.mydiv {
    position: relative;

}
.mywidth{
    width: 100% !important;
    height: 100% !important;

}

</style>
<script>
export default {
    methods: {
        openWhatsapp : function(){
            window.open("https://api.whatsapp.com/send?phone=+918660540863",'_self')
        }
    }
}
</script>