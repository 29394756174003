<template>
    
    <v-container fluid id="services" class="ml-3">
        <v-row>
            <v-col class="mr-2">
 <h6 class="text-uppercase font-weight-bold mr-5 pr-5">Services & Facilities</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                    </v-col>
        </v-row>
        <v-row justify="center" >
            <v-col cols="12" sm="6">
                <h3 class="text-center">NON INVASIVE CARDIOLOGY</h3>
                <ul class="mt-5">
                    <li v-for="text in noninvasive" class="pl-5 mx-auto pt-3 text-bold" :key="text"><p class="pr-2">{{text.replace("|","/").toUpperCase()}}</p></li>
                </ul>
            </v-col>
            <v-col cols="12" sm="6">
                                <h3 class="text-center">INVASIVE CARDIOLOGY</h3>
   <ul class="mt-5">
                    <li v-for="text in invasive" class="pl-5 mx-auto pt-3 text-bold" :key="text"><p class="pr-2">{{text.replace("|","/").toUpperCase()}}</p></li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>
<style  scoped>
h3{
       font-family: 'Muli' !important;
}
h2{
       font-family: 'Muli' !important;
}
ul{
list-style: none;
}
@media only screen and (max-width: 600px) {
p{
       font-size: 13px;
}
}
li{
    font-style: 'Roboto' ! important;
    font-weight: bold;
    ;
}
</style>

<script>
export default {
data: () => ({
    
    noninvasive : [ "ECG","2d echo & color doppler","transesophagial echo","stress/contrast/tissue doppler echo","speckled tracking","tread mill test"
    ,"24 hour ecg| 7day ecg | holter monitoring","24 hour ambulatory bp monitoring","PEDIATRIC/ NEONATAL ECHO",
    ],
    invasive : ["angiography","coronary angioplasty (primary | complex / calcified / left main / cto / bifurcation)","pacemaker / icd / crt implantation","peripheral angioplasty","electrophysiological study","radio frequency ablation",
    "rota ablation","intravascular lithotripsy (ivl)","intravascular ultrasound (ivus)","optical coherence technology (oct)","fractional flow reserve (ffr)","leadless pacemaker (micra)","orbital artherectomy","left bundle branch pacing"]
  }),
  }
</script>