import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
Vue.config.productionTip = false
var firebaseConfig = {
  apiKey: "AIzaSyD2H80K_PW8xMdp2TS6Yul7bGkh0QodW6Y",
  authDomain: "docwebsite-4d978.firebaseapp.com",
  databaseURL: "https://docwebsite-4d978.firebaseio.com",
  projectId: "docwebsite-4d978",
  storageBucket: "docwebsite-4d978.appspot.com",
  messagingSenderId: "409843474967",
  appId: "1:409843474967:web:3e46edfe96cb170328f252",
  measurementId: "G-Z8H897NP8Y"
};
firebase.default.initializeApp(firebaseConfig);
firebase.default.analytics();
new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
