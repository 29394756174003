<template>
<v-container fluid id="contact us" class="p-0 m-0">
<footer class="page-footer font-small unique-color-dark ml-3">

  <div style="background-color: rgb(238,52,78);">
    <div class="container">

      <!-- Grid row-->
      <div class="row py-4 d-flex align-items-center">

        <!-- Grid column -->
        <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
          <h6 class="mb-0">Get connected with us on social networks!</h6>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 col-lg-7 text-center text-md-right">

          <!-- Facebook -->
          <a class="fb-ic" href="https://www.facebook.com/Dr-Nikhil-Cardiologist-100839255125803/">
            <i class="fab fa-facebook-f white-text mr-4"> </i>
          </a>
          <!-- Twitter -->
         
          <!--Instagram-->
          <a class="ins-ic" href="https://www.instagram.com/dr_nikhilp_cardiologist/">
            <i class="fab fa-instagram white-text mr-4"> </i>
          </a>
           <a class="ins-ic"  @click="openWhatsapp">
            <i class="fab fa-whatsapp white-text mr-4"> </i>
          </a>
            <a class="ins-ic" href="https://twitter.com/dr_nikhilp/">
            <i class="fab fa-twitter white-text mr-4"> </i>
          </a>
            <a class="ins-ic" href="https://www.youtube.com/channel/UCS2an6rF-efnCWpCikiFP-A">
            <i class="fab fa-youtube white-text mr-4"> </i>
            
          </a>
 <a class="ins-ic" href="https://www.linkedin.com/in/drnikhilpcardiologist">
            <i class="fab fa-linkedin white-text mr-4"> </i>
            
          </a>
        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row-->

    </div>
  </div>

  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left mt-5">

    <!-- Grid row -->
    <div class="row mt-3 mr-5">

     
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 col-xl-12 ">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contact Us</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p class="text-left"> 
          <i class="fas fa-home mr-3"></i>A-101,Om Rachana CHS, Plot no.3,Sector 17, Near Dominos Pizza & Tanishq, Vashi-400703 </p>
  <p class="text-left">
          <i class="fas fa-phone mr-3"></i> 8660540863</p>
        <p class="text-left">
          <i class="fas fa-envelope mr-3"></i> nmonty02@gmail.com</p>
  
        <a class="li-ic" href="https://www.facebook.com/Dr-Nikhil-Cardiologist-100839255125803/">
            <i class="fab fa-facebook white-text mr-4"> </i>
          </a>
          <!--Instagram-->
          <a class="ins-ic" href="https://www.instagram.com/dr_nikhilp_cardiologist/">
            <i class="fab fa-instagram white-text mr-4"> </i>
          </a>
 <a class="ins-ic" href="#" @click="openWhatsapp">
            <i class="fab fa-whatsapp white-text mr-4"> </i>
          </a>
           <a class="ins-ic" href="https://twitter.com/dr_nikhilp/">
            <i class="fab fa-twitter white-text mr-4"> </i>
          </a>
           <a class="ins-ic" href="https://www.youtube.com/channel/UCS2an6rF-efnCWpCikiFP-A">
            <i class="fab fa-youtube white-text mr-4"> </i>
          </a>     
          <a class="ins-ic" href="https://www.linkedin.com/in/drnikhilpcardiologist">
            <i class="fab fa-linkedin white-text mr-4"> </i>
            
          </a>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  
</footer>
</v-container>
  

</template>
<style  scoped>
i{
  color: black;
}
</style>
<script>
export default {
    methods: {
        openWhatsapp : function(){
            window.open("https://api.whatsapp.com/send?phone=+918660540863",'_self')
        }
    }
}
</script>