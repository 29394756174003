<template>
  <v-app class="p-0 m-0">
    <MyAppBar/>
 <v-card
    class="px-0 d-block d-sm-none"
  >

    <v-app-bar
      color="#0c263b"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-toolbar-title class="text-white" >Dr Nikhil P</v-toolbar-title>


      <v-spacer></v-spacer>
       <a href="https://www.facebook.com/Dr-Nikhil-Cardiologist-100839255125803/" class="pr-2"><v-img :src="require(`./assets/images/facebook.png`)"></v-img></a>
       <a href="https://www.instagram.com/dr_nikhilp_cardiologist/" class="pr-2"> <v-img :src="require(`./assets/images/instagram.png`)"></v-img></a>
         <a href="https://api.whatsapp.com/send?phone=+918660540863" class="pr-2"><v-img :src="require(`./assets/images/whatsapp.png`)"></v-img></a>

    
    </v-app-bar>
     

    <v-navigation-drawer
      v-model="drawer"
      absolute
      height="100vh"
      temporary
    >
    


      <v-list dense>
        <v-list-item
          v-for="header in headers"
          :key="header"
        >


          <v-list-item-content>
            <v-list-item-title><a  class="mytitle" @click="scrollTo(header)">{{ header }}</a></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


  
  
  </v-card>
    <v-main class="m-0 p-0">
      <Content/>
    </v-main>
  </v-app>
</template>
<style >
body{
  overflow-x : hidden;
}
.mytitle
{
font-family: 'Nunito';
      color: rgb(39,51,83) !important;

}
a{
      color: rgb(39,51,83) !important;

}
a:hover{
  color: rgb(238,52,78) !important;
  text-decoration: none;
}
html{
  scroll-behavior: smooth;
}
button:focus {outline:0;}

</style>
<script>
import MyAppBar from './components/MyAppBar';
import Content from './components/Content';

export default {
  name: 'App',

  components: {
    Content,
   MyAppBar
  },

  data: () => ({
      headers : [ "Home","Services","About Us","Testimonials","Gallery","Contact Us"],
    drawer: false,
      group: null,
       items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
  }),
   watch: {
      group () {
        this.drawer = false
      },
    },
     methods : {
    openWhatsapp : function(){
                  window.open("https://api.whatsapp.com/send?phone=+918660540863",'_self')

    },

    scrollTo : function(id){
      console.log(id.toLowerCase());
      this.drawer  =false;

      document.getElementById(id.toLowerCase()).scrollIntoView();

    }
  
  }
};
</script>
