<template>
<v-container fluid class="">

<v-row class="m-0 p-0"  id="gallery"  >
    <v-col class="my-0 py-0">
 <h6 class="text-uppercase font-weight-bold ml-2">gallery</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto pl-3" style="width: 60px;">
            </v-col>
</v-row>
 <v-row class="p-3">
    <v-col
      v-for="n in 6"
      :key="n"
      class="d-flex "
      cols="12" sm="4"
    >
      <v-img
        :src="require(`../assets/images/${n + 3}.jpg`)"
          contain max-height="500px"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
  </v-container>

</template>

<style scoped>
.blackbar
{
    border: 1px solid black;
    margin: 0 auto 16px;
    width: 50px;
}
p{
font-family: 'Architects Daughter';
    color: black;
    font-size: 24px;
    }
</style>
<script>


export default {
  
}
</script>