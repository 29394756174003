<template>
<v-container fluid class="m-0 p-0">
   

<v-app-bar color="#0c263b" class="p-0 m-0 d-none d-sm-block" height="150px" elevation="0" hide-on-scroll>
 
  <v-container fluid class="m-0 p-0 fullheight">
    <v-row class="p-0 m-0 fullheight">
      <v-col md="0"  class="myborder m-0 p-0 white d-none d-lg-block">
        <v-img :src="require(`../assets/images/logo.png`)" class="p-0 m-0 white" max-height="150px" aspect-ratio="1"  contain ></v-img>
        </v-col>

      <v-col class="p-0 m-0 fullheight" md="12" lg="10" >
      
      
       <ul class="d-flex fullheight">
         <li class="x"   v-for="header in headers" :key="header"><a  @click="scrollTo(header)">{{getHeaderName(header)}}</a></li>
        <li class="x"> <a href="https://www.facebook.com/Dr-Nikhil-Cardiologist-100839255125803/"><v-img :src="require(`../assets/images/facebook.png`)"></v-img></a></li>
        <li class="x"><a href="https://www.instagram.com/dr_nikhilp_cardiologist/"> <v-img :src="require(`../assets/images/instagram.png`)"></v-img></a></li>
        <li class="x"> <a href="https://api.whatsapp.com/send?phone=+918660540863"><v-img :src="require(`../assets/images/whatsapp.png`)"></v-img></a></li>
        <li class="x"> <a href="https://twitter.com/dr_nikhilp/"><v-img :src="require(`../assets/images/twitter.png`)"></v-img></a></li>
        <li class="x"> <a href="https://www.youtube.com/channel/UCS2an6rF-efnCWpCikiFP-A"><v-img :src="require(`../assets/images/youtube.png`)"></v-img></a></li>
        <li class="x"> <a href="https://www.linkedin.com/in/drnikhilpcardiologist"><v-img :src="require(`../assets/images/linkedin.png`)"></v-img></a></li>

       </ul>
      </v-col>
     
    </v-row>
  </v-container>
   </v-app-bar>
 
</v-container>
  

</template>

<style scoped>
.mymargin
{
}
.wcolor
{
  color : #25d366;
}
.fcolor
{
  color: #1877f2;
}
.mywhite{
  color: white;
}
.negativemargin
{
  margin: -10%;
}
.myspan
{
  text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    color: #ffff;
}
.fa-instagram
{
   background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
}
body{
  font-family: Muli, sans-serif;
    font-size: 16px;
    line-height: 1.7;
    color: rgb(130, 134, 144);
    text-transform: none;
    font-weight: 400;
    font-style: normal;
}
.test 
{
    align-items: center;

}
.myborder
{
      border-right: 1px solid rgb(238, 238, 238);

}
.buttona{
  background-color: rgb(12, 38, 59);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    position: relative;
    letter-spacing: 1px;
    padding: 18px 40px;
    border-radius: 6px;
    transition: all 0.25s ease-in-out 0s;
}
ul{
  display: flex !important;
  list-style-type : none;
  align-items: center;
  }
.fullheight
{
  height: 100%;
}

a{
      color: white !important;

}
a:hover{
  color: rgb(238,52,78) !important;
  text-decoration: none;
}
v-toolbar-title
{
  font-family: 'Nunito';
}
li 
{
      font-size: 1em;
      margin: 0px 20px;
        max-width: 50%;

      font-weight: 800;
      font-family: 'Nunito';
    padding-right: 20px ;
}
/* button{
background-color: rgb(12, 38, 59);
    color: rgb(255, 255, 255);
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    position: relative;
    letter-spacing: 1px;
    padding: 18px 40px;
    border-radius: 6px;
    transition: all 0.25s ease-in-out 0s;
} */
@media only screen and (min-width: 1200px ) {

li{
  font-size: 14px !important;
}
}
@media only screen and (max-width: 1040px ) {

li{
  font-size: 12px !important;
  padding-right: 10px;
}
}
</style>
<script>

export default {
data: () => ({
    
    headers : [ "Home","Services","About Us","Testimonials","Gallery","Contact Us"],
    drawer: false,
      group: null,
       items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
  }),
  watch: {
      group () {
        this.drawer = false
      },
    },
  methods : {
    openWhatsapp : function(){
                  window.open("https://api.whatsapp.com/send?phone=+918660540863",'_self')

    },
    getHeaderName: function(name){
      if(name.includes("Us"))
      return name.split(" ")[0]
      else 
      return name;
    },

    scrollTo : function(id){
      console.log(id.toLowerCase());

      document.getElementById(id.toLowerCase()).scrollIntoView();

    }
  }
  }
</script>