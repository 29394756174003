<template>
<v-container fluid class="m-0 p-0">
 
     <v-row>
       <CoverComponent/>
       <AboutUs/>
       <Testimonials/>

       <Services/>
              <Gallery/>

       <ContactUs/>
     </v-row>
</v-container>
  
</template>
<style scoped >
.tp{
  height: 1000px;
}
h2{
    font-family: 'lato';
    font-size: 56px;
    color: red;
}
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 32px   ;
  }
}

</style>
<script>
import AboutUs from './AboutUs';
import Gallery from './Gallery.vue';
import ContactUs from './ContactUs.vue';
import Services from './Services.vue';
import CoverComponent from './CoverComponent.vue';
import Testimonials from './Testimonials.vue';

export default {
  components : {
  Gallery,
  ContactUs,
  CoverComponent,
  Services,
  Testimonials,
  AboutUs
  }
}
</script>